<template>
  <div>
    <b-row>
      <!-- select grupos -->
      <b-col lg="9" md="9" sm="12">
        <validation-provider #default="{ errors }" name="grupos">
          <b-form-group
            label="Adicione grupos*"
            label-for="grupos"
            :state="errors.length > 0 || form.errors.grupos ? false : null"
          >
            <v-select
              id="grupos"
              v-model="form.grupos"
              variant="primary"
              :options="form.opcoes"
              label="descricao"
              :clearable="false"
              multiselect
              multiple
              placeholder="Selecione um ou mais grupos"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small v-if="errors[0]" class="text-danger d-block">
              Este campo é de preenchimento obrigatório.
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- add grupo aos selecionados -->
      <b-col lg="3" md="3" sm="12">
        <b-button
          class="mr-2 mt-2 px-1 w-100"
          variant="custom-blue"
          @click="addGruposSession()"
          :disabled="form.grupos.length == 0"
        >
          <feather-icon icon="CheckIcon" class="mr-25" />
          <span>Adicionar grupo</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- listagem grupos selecionados -->
    <div :key="updated">
      <b-row v-if="form.gruposSelecionados.length == 0">
        <b-col>
          <h5 class="pb-1">Por favor, selecione um ou mais grupos</h5>
        </b-col>
      </b-row>
      <div v-else class="mb-2 border rounded pt-0 pb-2 px-1">
        <b-row
          v-for="grupo in form.gruposSelecionados"
          :key="grupo.id_grupo"
          class="px-1"
        >
          <b-col class="border-bottom p-0 d-flex align-items-center" cols="11">
            <h5 class="my-1">{{ grupo.descricao }}</h5>
          </b-col>
          <b-col class="border-bottom p-0 text-right d-flex align-items-center justify-content-end" cols="1">
            <a @click="modalConfirmacaoDeletar(grupo)">
              <feather-icon icon="XIcon" size="18"/>
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import {
  CampanhaNacionalGetFormGrupos,
  CampanhaNacionalSetGruposSelecionadoPorVacina,
  CampanhaNacionalGetGruposSelecionadoPorVacina,
  CampanhaNacionalDeleteGrupoSelecionado,
  CampanhaNacionalGetState,
} from "@/views/custom-pages/campanha-nacional/Helpers/campanhaNacionalHelpers.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    CampanhaNacionalGetFormGrupos,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    vacina: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updated: null,
      form: {
        opcoes: [],
        grupos: [],
        gruposSelecionados: [],
        errors: {
          grupos: null,
        },
      },
    };
  },
  beforeMount() {
    this.handleOpcoes();
  },
  methods: {
    handleOpcoes() {
      let grupos = CampanhaNacionalGetFormGrupos(),
        gruposSelecionados = CampanhaNacionalGetGruposSelecionadoPorVacina(
          this.vacina
        ),
        idsParaOcultar = gruposSelecionados.map((grupo) => grupo.id_grupo);

      this.form.opcoes = grupos.filter(
        (grupo) => !idsParaOcultar.includes(grupo.id_grupo)
      );
      this.form.grupos = [];
    },
    handleGruposSelecionados() {
      this.form.gruposSelecionados =
        CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina);
    },
    addGruposSession() {
      CampanhaNacionalSetGruposSelecionadoPorVacina(
        this.vacina,
        this.form.grupos
      );
      this.handleOpcoes();
    },
    removeGrupoSession(grupo) {
      CampanhaNacionalDeleteGrupoSelecionado(this.vacina, grupo);
      this.handleGruposSelecionados();
    },
    emitGrupoCompleto(){
      if(CampanhaNacionalGetGruposSelecionadoPorVacina(this.vacina).length){
        this.$emit('groupComplete', {id_vacina: this.vacina.id_vacina, completo:true})
      }else{
        this.$emit('groupComplete', {id_vacina: this.vacina.id_vacina, completo:false})
      }
    },
    modalConfirmacaoDeletar(grupo) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          html: `<h5>Você perderá os dados preenchidos em precificação e distribuição.</br></br>Você quer remover o grupo ${grupo.descricao}?</h5>`,
          icon: "warning",
          iconColor: "red",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Remover",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "mr-2",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.removeGrupoSession(grupo);
          }
        });
    },
  },

  watch: {
    "$store.state.campanhaNacionalState.watchers.grupos": {
      immediate: true,
      handler() {
        this.handleGruposSelecionados();
        this.handleOpcoes();
        this.emitGrupoCompleto();
        this.updatedAt = CampanhaNacionalGetState().watchers.grupos;
      },
    },
  },
};
</script>

